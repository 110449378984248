import {
  SET_LANGUAGES
} from "../../constants/actionTypes";

const INIT_STATE = {
  languages: [
    'en'
  ]
};

const languageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      }
    default:
      return state;
  }
};

export default languageReducer;
