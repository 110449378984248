import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    CToast,
    CToastBody,
    CToastHeader,
    CToaster,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';

const NotificationToster = ({ message }) => {

    // console.log('message', message);

    const [toasts, setToasts] = useState([]);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (message && message.body) {
                let oldToasts = [...toasts];
                oldToasts.push({ position: 'top-right', autohide: 2000, closeButton: true, fade: true });
                setToasts(oldToasts);
            }
        }
        return () => {
            mounted = false;
        }
    }, [message]);


    const toasters = (() => {
        return toasts.reduce((toasters, toast) => {
            toasters[toast.position] = toasters[toast.position] || []
            toasters[toast.position].push(toast)
            return toasters
        }, {})
    })()


    return (<>
        {Object.keys(toasters).map((toasterKey) => (
            <CToaster
                position={toasterKey}
                key={'toaster' + toasterKey}
            >
                {
                    toasters[toasterKey].map((toast, key) => {
                        return (
                            <CToast
                                key={'toast' + key}
                                show={true}
                                autohide={toast.autohide}
                                fade={toast.fade}
                                className="notification-toast"
                            >
                                <div className="d-flex">
                                    <div className={`notification-icon ${message ? message.type : "info"}`}>
                                        {message && message.type == "success" &&
                                            <CIcon name="cil-check-circle" />
                                        }
                                        {message && message.type == "error" &&
                                            <CIcon name="cil-x-circle" />
                                        }
                                        {message ?
                                            (message.type == "info" || message.type == "")  &&
                                            <CIcon name="cil-asterisk-circle" />
                                            :
                                            <CIcon name="cil-asterisk-circle" />
                                        }
                                    </div>
                                    <div className="notification-message">
                                        <CToastHeader closeButton={toast.closeButton}>
                                            {message ? message.title : ""}
                                        </CToastHeader>
                                        <CToastBody>
                                            {message ? message.body : ""}
                                        </CToastBody>
                                    </div>
                                </div>
                            </CToast>
                        )
                    })
                }
            </CToaster>
        ))}
    </>)
}

const mapStateToProps = (state) => {
    return {
        message: state.message.message
    };
};

export default connect(mapStateToProps, null)(NotificationToster);
