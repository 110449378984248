import StorageService from "../app/storage.service";

export default function authHeader() {
    const token = StorageService.get('token');

    if (token) {
        return { token: token };
    } else {
        return {};
    }
}
