import { apiUrl } from "src/config/apiConfig";

// Base Url 
export const ADMIN_API_BASE = apiUrl + "admin"; //WITHOUT TRAILING SLASH @ END
export const PUBLIC_API_BASE = apiUrl + "api";

/*
* Api Endpoints
* Endpoint Must Start With Slash
*/

//Auth Endpoints
export const ADMIN_LOGIN = '/login';
export const ADMIN_VERIFY_TOKEN = '/verifyToken';
export const ADMIN_DASHBOARD = '/dashboard';

export const ADMIN_ZONE_LIST = '/zones';
export const ADMIN_ZONE_SEARCH = '/zoneSearch';
export const PUBLIC_ZONE_ALL_LIST = '/zoneList';
export const ADMIN_ZONE_DETAILS = '/zone-info';
export const ADMIN_ZONE_CREATE = '/create-zone';
export const ADMIN_ZONE_UPDATE = '/update-zone';
export const ADMIN_ZONE_DELETE = '/destroy-zone';

export const ADMIN_REGION_LIST = '/regions';
export const ADMIN_REGION_SEARCH = '/regionSearch';
export const ADMIN_REGION_DETAILS = '/region-info';
export const ADMIN_REGION_CREATE = '/create-region';
export const ADMIN_REGION_UPDATE = '/update-region';
export const ADMIN_REGION_DELETE = '/destroy-region';

export const ADMIN_CATEGORY_LIST = '/categories';
export const ADMIN_CATEGORY_SEARCH = '/catSearch';
export const PUBLIC_CATEGORY_ALL_LIST = '/allCategories';
export const PUBLIC_CATEGORY_WISE_SUB_CATEGORIES = '/catWisesubCatsPublic';
export const PUBLIC_CATEGORY_WISE_SHOPS = '/catWiseShopList';
export const ADMIN_CATEGORY_DETAILS = '/category-info';
export const ADMIN_CATEGORY_CREATE = '/create-category';
export const ADMIN_CATEGORY_UPDATE = '/update-category';
export const ADMIN_CATEGORY_DELETE = '/destroy-category';

export const ADMIN_SUB_CATEGORY_LIST = '/subcategories';
export const ADMIN_SUB_CATEGORY_SEARCH = '/searchSubcat';
export const PUBLIC_SUB_CATEGORY_ALL_LIST = '/allSubCategories';
export const PUBLIC_SUB_CATEGORY_WISE_PRODUCTS = '/subcatProducts';
export const ADMIN_SUB_CATEGORY_DETAILS = '/subcategory-info';
export const ADMIN_SUB_CATEGORY_CREATE = '/create-subcatgory';
export const ADMIN_SUB_CATEGORY_UPDATE = '/update-subcatgory';
export const ADMIN_SUB_CATEGORY_DELETE = '/destroy-subcatgory';

export const ADMIN_BRAND_LIST = '/brands';
export const ADMIN_BRAND_SEARCH = '/brandSearch';
export const PUBLIC_BRAND_ALL_LIST = '/allBrands';
export const ADMIN_BRAND_DETAILS = '/brand';
export const ADMIN_BRAND_CREATE = '/create-brand';
export const ADMIN_BRAND_UPDATE = '/update-brand';
export const ADMIN_BRAND_DELETE = '/destroy-brand';

export const ADMIN_SHOP_LIST = '/shops-list';
export const ADMIN_SHOP_SEARCH = '/shopSearch';
export const PUBLIC_SHOP_ALL_LIST = '/allShop';
export const ADMIN_SHOP_DETAILS = '/shopView';
export const ADMIN_SHOP_CREATE = '/create-shop';
export const ADMIN_SHOP_UPDATE = '/update-shop';
export const ADMIN_SHOP_UPDATE_STATUS = '/shop-status-change';
export const ADMIN_SHOP_UPDATE_FEATURED = '/shop-featured-change';
export const ADMIN_SECTION_UPDATE_COMMISSION = '/set-commission';
export const ADMIN_SHOP_DELETE = '/destroy-shop';

export const ADMIN_SLIDER_LIST = '/sliders';
export const ADMIN_SLIDER_SEARCH = '/sliderSearch';
export const ADMIN_SLIDER_DETAILS = '/slider';
export const ADMIN_SLIDER_CREATE = '/create-slider';
export const ADMIN_SLIDER_UPDATE = '/update-slider';
export const ADMIN_SLIDER_DELETE = '/destroy-slider';

export const ADMIN_EVENT_LIST = '/events';
export const ADMIN_EVENT_SEARCH = '/eventSearch';
export const PUBLIC_EVENT_ALL_LIST = '/eventList';
export const ADMIN_EVENT_DETAILS = '/event';
export const ADMIN_EVENT_CREATE = '/create-event';
export const ADMIN_EVENT_UPDATE = '/update-event';
export const ADMIN_EVENT_DELETE = '/destroy-event';

export const ADMIN_COUPON_LIST = '/couponList';
export const ADMIN_COUPON_SEARCH = '/couponSearch';
export const ADMIN_COUPON_DETAILS = '/coupon';
export const ADMIN_COUPON_CREATE = '/create-coupon';
export const ADMIN_COUPON_UPDATE = '/update-coupon';
export const ADMIN_COUPON_DELETE = '/destroy-coupon';


export const ADMIN_LOCALIZATION_LIST = '/localizationList';
export const ADMIN_LOCALIZATION_ACTIVE_LIST = '/activeLocalizations';
export const ADMIN_LOCALIZATION_SEARCH = '/localizationSearch';
export const ADMIN_LOCALIZATION_DETAILS = '/localization';
export const ADMIN_LOCALIZATION_CREATE = '/create-localization';
export const ADMIN_LOCALIZATION_UPDATE = '/update-localization';
export const ADMIN_LOCALIZATION_DELETE = '/destroy-localization';


export const ADMIN_SITE_SETTINGS_DETAILS = '/siteSetting';
export const ADMIN_SITE_SETTINGS_UPDATE = '/site-setting';

export const ADMIN_SECTION_LIST = '/sectionList';
export const ADMIN_SECTION_SEARCH = '/sectionSearch';
export const ADMIN_SECTION_DETAILS = '/section';
export const ADMIN_SECTION_CREATE = '/create-section';
export const ADMIN_SECTION_UPDATE = '/update-section';
export const ADMIN_SECTION_DELETE = '/destroy-section';
export const ADMIN_SECTION_UPDATE_PRIORITY = '/section-priority-update';

export const ADMIN_FAQ_LIST = '/faqList';
export const ADMIN_FAQ_SEARCH = '/faqSearch';
export const ADMIN_FAQ_DETAILS = '/faq';
export const ADMIN_FAQ_CREATE = '/create-faq';
export const ADMIN_FAQ_UPDATE = '/update-faq';
export const ADMIN_FAQ_DELETE = '/destroy-faq';

export const ADMIN_PRODUCT_LIST = '/allProducts';
export const ADMIN_PRODUCT_SEARCH = '/productSearch';
export const ADMIN_PRODUCT_SEARCH_PUBLIC = '/productSearchPublic';
export const ADMIN_PRODUCT_DETAILS = '/productView';
export const ADMIN_PRODUCT_UPDATE = '/product-update';
export const ADMIN_PRODUCT_DELETE = '/product-delete';
export const ADMIN_PRODUCT_APPROVAL = '/product-approval';
export const ADMIN_PRODUCT_STATUS = '/product-status-change';
export const ADMIN_PRODUCT_FILTER_BY_DATE = '/products-filter';

export const ADMIN_ORDER_LIST = '/orders';
export const ADMIN_ORDER_SEARCH = '/orderSearch';
export const ADMIN_ORDER_DETAILS = '/order';
export const ADMIN_ORDER_FILTER_BY_DATE = '/orders-filter';

export const ADMIN_CUSTOMER_LIST = '/customer-list';
export const ADMIN_CUSTOMER_SEARCH = '/customerSearch';
export const ADMIN_CUSTOMER_DELETE = '/delete-customer';
export const ADMIN_CUSTOMER_STATUS = '/customer-status-chnage';

export const ADMIN_DRIVER_LIST = '/drivers';
export const ADMIN_DRIVER_SEARCH = '/driverSearch';
export const ADMIN_DRIVER_DETAILS = '/driver';
export const ADMIN_DRIVER_DELETE = '/driver-destroy';
export const ADMIN_DRIVER_STATUS = '/driver-status-chnage';
export const ADMIN_DRIVER_TRANSACTION_LIST = '/driverTransactions';
export const ADMIN_DRIVER_TRANSACTION_SEARCH_LIST = '/driverTransactionsSearch';
export const ADMIN_DRIVER_FILTER_BY_DATE = '/driverTransactionsFilter';
export const ADMIN_DRIVER_PENDING_PAYOUTS = '/driver-pending-payouts';
export const ADMIN_DRIVER_PAYMENT = '/payDriver';
export const ADMIN_DRIVER_PAYOUTS = '/driver-payouts';
export const ADMIN_DRIVER_PAYOUTS_SEARCH = '/driverPayoutSearch';
export const ADMIN_DRIVER_PAYOUTS_DATE_FILTER = '/driverPayoutsFilter';
export const ADMIN_DRIVER_PAYOUTS_FIRST_DATE = '/driverpayoutsdateFind';

export const ADMIN_SELLER_PAYMENT = '/paySeller';
export const ADMIN_SELLER_PAYOUTS = '/seller-payouts';
export const ADMIN_SELLER_PAYOUTS_SEARCH = '/sellerPayoutSearch';
export const ADMIN_SELLER_PAYOUTS_DATE_FILTER = '/sellerPayoutsFilter';
export const ADMIN_SELLER_PAYOUTS_FIRST_DATE = '/merchantpayoutsdateFind';

export const ADMIN_REVIEW_LIST = '/reviews';
export const ADMIN_REVIEW_SEARCH = '/searchReview';
export const ADMIN_REVIEW_DELETE = '/delete-review';
export const ADMIN_REVIEW_STATUS = '/review-status-update';

export const ADMIN_CONTACT_LIST = '/contactList';
export const ADMIN_CONTACT_SEARCH = '/contactListSearch';
export const ADMIN_CONTACT_DELETE = '/contact-delete';

export const ADMIN_NEWSLETTER_LIST = '/newsleters';
export const ADMIN_NEWSLETTER_SEARCH = '/newsletterSearch';
export const ADMIN_NEWSLETTER_SENT = '/newsletterSent';
export const ADMIN_NEWSLETTER_UNSUBSCRIBE = '/newsletterDelete';

export const ADMIN_NOTIFICATION_LIST = '/notifications';
export const ADMIN_NOTIFICATION_SEARCH = '/notificationSearch';
export const ADMIN_NOTIFICATION_LATEST = '/latestNotification';

export const ADMIN_CURRENCY_LIST = '/currencyList';
export const ADMIN_CURRENCY_UPDATE = '/currency-update';

export const ADMIN_ROLE_LIST = '/roleList';
export const ADMIN_ROLE_ALL_LIST = '/allRoleList';
export const ADMIN_ROLE_SEARCH = '/roleSearch';
export const ADMIN_ROLE_DETAILS = '/roleInfo';
export const ADMIN_ROLE_PERMISSIONS = '/rolePermissionList';
export const ADMIN_ROLE_CREATE = '/add-role';
export const ADMIN_ROLE_UPDATE = '/update-role';
export const ADMIN_ROLE_DELETE = '/delete-role';

export const ADMIN_PERMISSION_ALL_LIST = '/permissionList';

export const ADMIN_USER_LIST = '/list';
export const ADMIN_USER_SEARCH = '/adminsSearch';
export const ADMIN_USER_DETAILS = '/getInfo';
export const ADMIN_USER_CREATE = '/add';
export const ADMIN_USER_UPDATE = '/update';
export const ADMIN_USER_UPDATE_PROFILE = '/profile-update';
export const ADMIN_USER_DELETE = '/delete';

export const ADMIN_PAYMENT_GATEWAY_LIST = '/paymentGatewayList';
export const ADMIN_PAYMENT_GATEWAY_SEARCH = '/paymentGatewaySearch';
export const ADMIN_PAYMENT_GATEWAY_DETAILS = '/payment-gateway';
export const ADMIN_PAYMENT_GATEWAY_CREATE = '/create-payment-gateway';
export const ADMIN_PAYMENT_GATEWAY_UPDATE = '/update-payment-gateway';
export const ADMIN_PAYMENT_GATEWAY_DELETE = '/destroy-payment-gateway';

export const ADMIN_VEHICLE_LIST = '/vehicles';
export const ADMIN_VEHICLE_ALL_LIST = '/allVehicles';
export const ADMIN_VEHICLE_SEARCH = '/vehiclesSearch';
export const ADMIN_VEHICLE_DETAILS = '/vehicle';
export const ADMIN_VEHICLE_CREATE = '/create-vehicle';
export const ADMIN_VEHICLE_UPDATE = '/update-vehicle';
export const ADMIN_VEHICLE_DELETE = '/destroy-vehicle';

export const ADMIN_STAFF_LIST = '/staffList';
export const ADMIN_STAFF_ALL_LIST = '/staffAllList';
export const ADMIN_STAFF_SEARCH = '/staffSearch';
export const ADMIN_STAFF_DETAILS = '/staffInfo';
export const ADMIN_STAFF_CREATE = '/create-staff';
export const ADMIN_STAFF_UPDATE = '/update-staff';
export const ADMIN_STAFF_DELETE = '/destroy-staff';

export const ADMIN_ASSIGN_VEHICLE_LIST = '/assignVehicleList';
export const ADMIN_ASSIGN_VEHICLE_SEARCH = '/assignvehicleSearch';
export const ADMIN_ASSIGN_VEHICLE_DETAILS = '/assignVehicleInfo';
export const ADMIN_ASSIGN_VEHICLE_CREATE = '/assignVehicle';
export const ADMIN_ASSIGN_VEHICLE_DELETE = '/deleteAssignVehicle';

export const ADMIN_TRACKING_FILTER_BY_LOCATION = '/filterBylocation';
export const ADMIN_TRACKING_LIVE_ORDER = '/orderLiveTracking';

export const ADMIN_UPLOAD_IMAGE_IN_EDITOR = '/upload-image-in-editor';

export const ADMIN_JOB_LIST = '/jobList';
export const ADMIN_JOB_SEARCH = '/jobSearch';
export const ADMIN_JOB_SEARCH_APPLICANT = '/jobApplicantSearch';
export const ADMIN_JOB_DETAILS = '/jobView';
export const ADMIN_JOB_INFO = '/jobInfo';
export const ADMIN_JOB_CREATE = '/create-job';
export const ADMIN_JOB_UPDATE = '/update-job';
export const ADMIN_JOB_DELETE = '/destroy-job';

export const ADMIN_AD_LIST = '/adsLists';
export const ADMIN_AD_SEARCH = '/adsSearch';
export const ADMIN_AD_DETAILS = '/ads';
export const ADMIN_AD_CREATE = '/create-ads';
export const ADMIN_AD_UPDATE = '/update-ads';
export const ADMIN_AD_DELETE = '/destroy-ads';

export const ADMIN_FINANCE_REPORT = '/financeReport';
