import { LOGOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET, USER_PERMISSIONS_SET } from "../../constants/actionTypes";

const INIT_STATE = {
    token: sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null,
    user: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null,
    permission: sessionStorage.getItem('permissions') ? JSON.parse(sessionStorage.getItem('permissions')) : null
};

const authReducer = (state = INIT_STATE, action) => {
    switch (action.type) {

        case LOGOUT_USER_SUCCESS: {
            return {
                ...state,
                token: null,
                user: null,
                permission: null
            }
        }

        case USER_DATA: {
            return {
                ...state,
                user: action.payload,
            };
        }

        case USER_TOKEN_SET: {
            return {
                ...state,
                token: action.payload,
            };
        }

        case USER_PERMISSIONS_SET: {
            return {
                ...state,
                permission: action.payload,
            };
        }

        default:
            return state;
    }
}

export default authReducer;