import {
    SET_LANGUAGES
} from "../../constants/actionTypes";


export const setLanguages = (languages) => dispatch => {
    dispatch({
        type: SET_LANGUAGES,
        payload: languages,
    })
}