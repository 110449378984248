import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-tagsinput/react-tagsinput.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'rc-rate/assets/index.css';
import './scss/style.scss';

import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './redux/store';
import MainApp from "./containers/mainapp/index";

import { config, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
config.autoAddCss = false;
library.add(fas, fab);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const store = configureStore();

const App = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route path="/" component={MainApp} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </ConnectedRouter>
    </Provider>
  )
}

export default App;
