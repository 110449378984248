const STORAGE = sessionStorage;

class StorageService {

    static get(key) {
        let value = STORAGE.getItem(key);
        return value === null ? null : JSON.parse(value);
    }

    static set(key, value) {
        return STORAGE.setItem(key, JSON.stringify(value));
    }

    static unset(key) {
        if (this.isset(key))
            return STORAGE.removeItem(key);
        else
            return null;
    }

    static clear() {
        return STORAGE.clear();
    }

    static isset(key) {
        return this.get(key) !== null;
    }

}

export default StorageService;