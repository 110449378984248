import axios from "axios";

import StorageService from "../app/storage.service";

import { 
    ADMIN_API_BASE, 
    ADMIN_ROLE_PERMISSIONS,
    ADMIN_VERIFY_TOKEN,
    ADMIN_LOGIN 
} from "../../constants/api";
import { encryptString } from "src/helper/cryptograpgy";
import authHeader from "./auth-header";

class AuthService {

    static login(email, password) {
        return axios
            .post(ADMIN_API_BASE + ADMIN_LOGIN, { email, password })
            .then((response) => {
                const data = response.data.data.info;
                // console.log('data', response);
                if (data) {
                    StorageService.set("user", JSON.stringify(data));
                    StorageService.set("token", data.token);
                }

                return data;
            });
    }

    static getRoles(id) {
        return axios
            .get(ADMIN_API_BASE + ADMIN_ROLE_PERMISSIONS, {
                params: {
                    id: id
                }
            })
            .then((response) => {
                // console.log('rolePermissionList', response.data.data.rolePermissionList);
                const selectedPermissions = response.data && response.data.data && response.data.data.rolePermissionList && response.data.data.rolePermissionList.length > 0 ? response.data.data.rolePermissionList : null;
                // console.log('selectedPermissions', selectedPermissions);
                let selectedPermissionsDummy = [];
                if (selectedPermissions) {
                    selectedPermissionsDummy = selectedPermissions.map((permission) => {
                        if(permission && permission.Permission && permission.Permission.name) {
                            return permission.Permission.name;
                        }
                    })
                    StorageService.set("permissions", encryptString(selectedPermissionsDummy));
                }
                return encryptString(selectedPermissionsDummy);
            });
    }

    static verifyToken() {
        return axios
            .get(ADMIN_API_BASE + ADMIN_VERIFY_TOKEN, {
                headers: authHeader() 
            })
            .then((response) => {
                return response;
            });
    }

    static logout() {
        StorageService.unset("user");
        StorageService.unset("token");
        StorageService.unset("permissions");
    }

    // register(username, email, password) {
    //     return axios.post(API_URL + "signup", {
    //         username,
    //         email,
    //         password,
    //     });
    // }
}

export default AuthService;