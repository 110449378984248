import {
    FETCH_START,
    LOGOUT_USER_SUCCESS,
    USER_DATA,
    USER_TOKEN_SET,
    USER_PERMISSIONS_SET,
    SET_MESSAGE,
} from "../../constants/actionTypes";

import AuthService from "../../services/api/auth.service";

export const login = (email, password) => (dispatch) => {
    const e = email ? email : '';
    const p = password ? password : '';
    return AuthService.login(e, p).then(
        (data) => {
            dispatch({
                type: USER_DATA,
                payload: { user: data },
            });
            dispatch({
                type: USER_TOKEN_SET,
                payload: { user: data.token },
            });
            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: "success",
                    title: "Login",
                    body: "Login Successfully"
                },
            });

            AuthService.getRoles(data.role_id).then(
                (data) => {
                    // console.log('permission encrypted', data);
                    dispatch({
                        type: USER_PERMISSIONS_SET,
                        payload: { permission: data },
                    });
                    return Promise.resolve();
                },
                (error) => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
        
                    dispatch({
                        type: LOGOUT_USER_SUCCESS,
                    });
        
                    dispatch({
                        type: SET_MESSAGE,
                        payload: {
                            type: "error",
                            title: "Login",
                            body: message.toString()
                        },
                    });
        
                    return Promise.reject();
                }
            );

            //return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGOUT_USER_SUCCESS,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: {
                    type: "error",
                    title: "Login",
                    body: message.toString()
                },
            });

            return Promise.reject();
        }
    );
};

export const getUser = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        // axios.post(apiUrl + 'api/v1/auth/me',
        // ).then(({ data }) => {
        //     console.log("userSignIn: ", data);
        //     if (data.result) {
        //         dispatch({ type: FETCH_SUCCESS });
        //         dispatch({ type: USER_DATA, payload: data.user });
        //     } else {
        //         dispatch({ type: FETCH_ERROR, payload: data.error });
        //     }
        // }).catch(function (error) {
        //     dispatch({ type: FETCH_ERROR, payload: error.message });
        //     console.log("Error****:", error.message);
        // });
    }
};


export const logout = () => {
    return (dispatch) => {
        AuthService.logout();
        dispatch({ type: LOGOUT_USER_SUCCESS });
        dispatch({
            type: SET_MESSAGE,
            payload: {
                type: "success",
                title: "Login",
                body: "Logout Successfully"
            },
        });
    }
};


export const forgotPassword = ({ email }, props) => {
    const e = email ? email : '';
    // console.log("e:", e);
    const postData = new FormData();
    postData.append('email', e);
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        // axios.post(apiUrl + 'api/v1/admin/forgot-password', postData)
        //     .then(({ data }) => {
        //         console.log(data);
        //         if (data.err === false) {
        //             dispatch({ type: FETCH_SUCCESS });
        //             openNotificationWithIcon('success', 'Forgot password', data.msg);
        //             props.history.push('/signin')
        //         }
        //         if (data.err == true) {
        //             dispatch({ type: FETCH_SUCCESS });
        //             openNotificationWithIcon('error', 'Forgot password', data.msg);
        //         }
        //         if (data.errors) {
        //             dispatch({ type: FETCH_SUCCESS });
        //             data.errors.map(e => {
        //                 openNotificationWithIcon('error', e.param, e.msg);
        //             });
        //         }

        //     })
        //     .catch(function (error) {
        //         dispatch({ type: FETCH_SUCCESS, payload: 'Internal server error.' });
        //         openNotificationWithIcon('error', 'Sign in', 'Somthing went wrong, try again.');
        //         console.log("Error****:", error.message);
        //     });
    }
};
