import axios from "axios";
import { pageLimit } from "../../constants/tables";
import { 
    ADMIN_API_BASE, 
    ADMIN_LOCALIZATION_LIST,
    ADMIN_LOCALIZATION_ACTIVE_LIST,
    ADMIN_LOCALIZATION_SEARCH,
    ADMIN_LOCALIZATION_DETAILS,
    ADMIN_LOCALIZATION_CREATE,
    ADMIN_LOCALIZATION_UPDATE,
    ADMIN_LOCALIZATION_DELETE
} from "../../constants/api";
import authHeader from "./auth-header";

class LocalizationService {

    static getList(page = 1) {
        return axios
            .get(ADMIN_API_BASE + ADMIN_LOCALIZATION_LIST, {
                params: {
                    page: page,
                    limit: pageLimit
                },
                headers: authHeader()
            })
            .then((response) => {
                const data = response.data.data;
                return data;
            });
    }

    static getSearchList(page = 1, keyword = "") {
        return axios
            .get(ADMIN_API_BASE + ADMIN_LOCALIZATION_SEARCH, {
                params: {
                    page: page,
                    limit: pageLimit,
                    text: keyword
                },
                headers: authHeader()
            })
            .then((response) => {
                const data = response.data.data;
                return data;
            });
    }

    static getDetails(id) {
        return axios
            .get(ADMIN_API_BASE + ADMIN_LOCALIZATION_DETAILS, {
                params: {
                    id: id
                },
                headers: authHeader()
            })
            .then((response) => {
                // console.log('response', response);
                const data = response.data;
                return data;
            });
    }

    static add(item) {
        return axios
            .post(ADMIN_API_BASE + ADMIN_LOCALIZATION_CREATE, item, {
                headers: authHeader()
            })
            .then((response) => {
                // console.log('response', response);
                const data = response.data;
                return data;
            });
    }

    static update(item) {
        return axios
            .put(ADMIN_API_BASE + ADMIN_LOCALIZATION_UPDATE, item, {
                headers: authHeader()
            })
            .then((response) => {
                // console.log('response', response);
                const data = response.data;
                return data;
            });
    }

    static delete(id) {
        return axios
            .delete(ADMIN_API_BASE + ADMIN_LOCALIZATION_DELETE, {
                headers: authHeader(),
                data: {
                    id: id
                }
            })
            .then((response) => {
                // console.log('response', response);
                const data = response.data;
                return data;
            });
    }

    static getActiveList() {
        return axios
            .get(ADMIN_API_BASE + ADMIN_LOCALIZATION_ACTIVE_LIST, {
                headers: authHeader()
            })
            .then((response) => {
                const data = response.data.data;
                return data;
            });
    }
}

export default LocalizationService;