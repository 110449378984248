import { SWITCH_LANGUAGE, SWITCH_SIDEBAR } from "../../constants/actionTypes";

const INIT_STATE = {
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  sidebarShow: 'responsive'
};

const settingsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
      }
    case SWITCH_SIDEBAR:
      return {
        ...state,
        sidebarShow: action.payload,
      }
    default:
      return state;
  }
};

export default settingsReducer;
